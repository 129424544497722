import { Button, Flex } from 'components/primitives'
import { Content } from 'components/primitives/Dialog'
import {
  Root as DialogRoot,
  DialogTrigger,
  DialogPortal,
} from '@radix-ui/react-dialog'
import * as RadixDialog from '@radix-ui/react-dialog'
import {
  faMagnifyingGlass,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GlobalSearch from './GlobalSearch'
import SearchIcon from '../assets/svg/search.svg'

const MobileSearch = () => {
  const children = (
    <Flex
      css={{
        flexDirection: 'column',
        height: '100%',
        pt: '$4',
      }}
    >
      <GlobalSearch placeholder="Search collections and addresses" />
      <Flex
        css={{
          position: 'absolute',
          top: '$4',
          left: '$4',
        }}
        align="center"
        justify="between"
      >
        <RadixDialog.Close>
          <Flex
            css={{
              justifyContent: 'center',
              width: '44px',
              height: '44px',
              alignItems: 'center',
              borderRadius: 8,
              color: '$gray12',
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} width={16} height={16} />
            {/* <SearchIcon stroke="#fff" stroke-width="2" /> */}
          </Flex>
        </RadixDialog.Close>
      </Flex>
    </Flex>
  )
  return (
    <DialogRoot modal={false}>
      <DialogTrigger asChild>
        <Button
          css={{ justifyContent: 'center', width: '44px', height: '44px' }}
          type="button"
          size="small"
          color="ghost"
        >
          {/* <FontAwesomeIcon color='#fff' icon={faMagnifyingGlass} width={16} height={16} /> */}
          <SearchIcon stroke="#fff" stroke-width="2" />
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <Content
          onInteractOutside={(e) => {
            e.preventDefault()
          }}
          css={{
            width: '100%',
            height: '100%',
            borderRadius: '0px',
            border: '0px',
            minWidth: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            top: '0%',
            zIndex: 9999,
            background: [
              'linear-gradient(180deg, rgba(4, 15, 11, 0) 0%, rgba(4, 15, 11, 0.2736) 50.52%, rgba(4, 15, 11, 0.72) 84.37%)',
              'rgba(17, 17, 31, 1)',
            ].join(', '),
            backgroundBlendMode: 'multiply',
          }}
        >
          {children}
        </Content>
      </DialogPortal>
    </DialogRoot>
  )
}

export default MobileSearch
