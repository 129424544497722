import { Box } from 'components/primitives'
import { FC, ReactNode } from 'react'
import Navbar from './navbar'
import UnsupportedChainBanner from './UnsupportedChainBanner'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <Box
        id='app_layout'
        css={{
          // background: '$neutralBg',  
          background: [
            'linear-gradient(180deg, rgba(4, 15, 11, 0) 0%, rgba(4, 15, 11, 0.2736) 50.52%, rgba(4, 15, 11, 0.72) 84.37%)',
            'rgba(17, 17, 31, 1)',
          ].join(', '),
          backgroundBlendMode: 'multiply',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}
      >
        <Box css={{ maxWidth: 4500, mx: 'auto' }}>
          <Navbar />
          <UnsupportedChainBanner />
          <main>{children}</main>
        </Box>
      </Box>
    </>
  )
}

export default Layout
